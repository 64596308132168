import React from 'react'
import SEO from '../../components/seo'
import Layout from '../../components/layout'

import mainImg from '../../assets/images/main-cozmo-lir.jpg'

const Page = props => (
  <Layout>
    <SEO
      title="Anki — Cozmo is Lost in Reddit"
      description="Anki — Cozmo is Lost in Reddit"
    />

    <div id="main" className="alt">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h1>Anki — Cozmo is Lost in Reddit</h1>
          </header>
          <span className="image main">
            <img src={mainImg} alt="" />
          </span>
          <p>
            Cozmo is a loveable little robot created by Anki. The amount of
            robotics and AI tech packed into such a small package is incredible,
            but what makes Cozmo shine is his personality. To introduce that big
            personality to a new audience, Anki and W+K Lodge teamed up to bring
            Cozmo into a live-streamed interactive game experience on Reddit.
            For up to six hours, Redditors could collectively decide Cozmo's
            actions to navigate him through a series of themed worlds, based on
            nine popular Reddit subreddits, solving challenges and attempting to
            collect the keys needed to escape Reddit.
          </p>

          <div className="grid-wrapper">
            <div className="col-6">
              <p>
                It took a massive, talented team coordinating over three months
                to put together the whole production. Each of the nine worlds
                was meticulously handcrafted as a 1:24 scale set. BENT image lab
                partnered with us to design and build the sets that included all
                sorts of miniature props, mechanical motion, puppeteering
                apparatus, lighting effects, and easter eggs that related to the
                inside jokes within each subreddit.
              </p>
            </div>
            <div className="col-6">
              <span class="image fit">
                <img src={mainImg} alt="" />
              </span>
            </div>
          </div>
          <div className="grid-wrapper">
            <p>
              Cozmo does a great job of getting around the world on his own. In
              a live production where the internet is choosing his actions, he
              needs a little help, though. My first challenge for this project
              was to build software, using the Cozmo Python SDK, so two
              puppeteers could control Cozmo's movement and expressions. For
              this, I worked closely with our motion designer/puppeteers to
              design two primary input interfaces. The first was a PlayStation
              controller, using the two analog joysticks to control his speed
              and rotation. We also mapped Cozmo's lift functions and targeting
              capabilities to the controller buttons. The second interface was a
              keyboard with the keys assigned to Cozmo's vast array of
              expressive animations and sounds. As we rehearsed as a team, we
              refined and remapped the interfaces continuously until the
              puppeteers could allow Cozmo's personality to shine without having
              to think about it.
            </p>
            <p>
              To keep a tiny, animated robot running for six hours, live without
              a break, requires some additional engineering sleight-of-hand.
              Plenty of things can go wrong for the small robot in the live
              production environment, including wifi interference with all the
              wireless production communications, getting stuck on a piece of
              the set, and even losing parts after being launched with a
              trebuchet. However, the most significant engineering factor in
              succeeding in a show of this duration was keeping Cozmo's battery
              alive. His battery runs out after about 45 minutes. We decided to
              have a small army of Cozmo's that could we could rapidly swap out
              at any moment during the show.
            </p>
            <p>
              Swapping a small robot sounds straightforward, but the software
              that was connecting Cozmo with our two puppeteers required
              overcoming some technical hurdles. Each Cozmo communicates
              wirelessly through a secure connection with a single mobile
              device. The connection process takes time, which we couldn't
              afford to wait for, running live, when a swap was needed.
              Therefore, the software maintained a pool of Cozmo connections so
              that a second "on-deck" Cozmo was always pre-connected and ready
              to receive data from our puppeteer input server. The software
              blocked movement and sound instructions coming from the puppeteers
              from being run on the pre-connected Cozmo. As soon as a swap was
              needed, I switched an on-deck Cozmo out of standby mode to allow
              the puppeteer input to play.
            </p>
            <p>
              Having backups for our backups was also critical to a live event.
              Our custom Cozmo SDK client software was running on five separate
              physical worker machines, each of these connected to the
              puppeteering master server. If one of the worker processes threw
              an unrecoverable error, we could switch to one running in a good
              state. We also built a heads up display and control interface,
              that communicated with all of our Cozmo SDK clients via
              WebSockets, so that we could monitor the status of each Cozmo and
              manage the connections.
            </p>
            <p>
              Reddit can be unforgiving and unpredictable, especially when it
              comes to marketing. Players could have caused Cozmo to flounder
              endlessly or never find all the keys needed to escape. In the end,
              the self-aware humor, Cozmo's performance, and theatrical
              production won the crowd over. Two million people watched as over
              30,000 players worked together and finally helped Cozmo escape
              after 5 hours and 40 minutes.
            </p>
          </div>
        </div>
      </section>
    </div>
  </Layout>
)

export default Page
